import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const useGameSummery = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
      
        async getGameSummery(payload) {
            await axiosClient.post('/users/game_summary_module', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "gameSummery", data: res.data });
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getGameSummeryview(payload) {
            await axiosClient.post('/users/game_summary_module1', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "gameSummeryview", data: res.data });
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "person", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
    }),
};

export default useGameSummery;