import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const userProfile = {
    state: {
        bonus: [],
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({

    })
}

export default userProfile;