import axiosClient from "../../api/axiosClient";
import CSVDownload from "react-csv/src/components/Download";
import React from "react";
import customNotification from "../../components/notification";

const statisticsModel = {
  state: {
    chipsData: {},
    cashData: {},
    liveHistory: {},
    liveRefData: {},
    tableTrack: {},
    livePopupClose: false,
    notification: {
      data: [],
    },
    CsvComp: null,
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
  },
  effects: (dispatch) => ({
    async getNotificationStatistics(payload) {
      await axiosClient
        .post("/notification_text", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "notification", data: res.data.data });
          } else {
            this.setData({ name: "notification", data: res?.data.data });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async addNotificationStatistics(payload) {
      await axiosClient
        .post("/notification_text/insert", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getNotificationStatistics({
              page: "0",
              perpage: 10,
            });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async updateNotificationStatistics(payload) {
      await axiosClient
        .post("/notification_text/update", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getNotificationStatistics({
              page: "0",
              perpage: 10,
            });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async deleteNotificationStatistics(payload) {
      await axiosClient
        .post("/notification_text/delete", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getNotificationStatistics({
              page: "0",
              perpage: 10,
            });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async getChipsHistory(payload) {
      await axiosClient
        .post("/chip_history", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "chipsData", data: res.data });
          } else if (payload.is_download === 1 && !res.data.status) {
            this.setData({
              name: "CsvComp",
              data: <CSVDownload data={res.data} target="_blank" />,
            });
          } else {
            this.setData({ name: "chipsData", data: res?.data || {} });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          if (payload.is_download === 1) {
            this.setData({ name: "CsvComp", data: null });
          }
        });
    },
    async getCashHistory(payload) {
      await axiosClient
        .post("/cash_history", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "cashData", data: res.data });
          } else if (payload.is_download === 1 && !res.data.status) {
            this.setData({
              name: "CsvComp",
              data: <CSVDownload data={res.data} target="_blank" />,
            });
          } else {
            this.setData({ name: "cashData", data: res?.data || {} });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          if (payload.is_download === 1) {
            this.setData({ name: "CsvComp", data: null });
          }
        });
    },
    async getLiveHistory(payload) {
      await axiosClient
        .post("/users/live_table", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.getLiveHistory({ ...payload, page: `${+payload.page - 1}` });
              return;
            }
            this.setData({ name: "liveHistory", data: res.data });
          } else if (payload.is_download === 1) {
            // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" />});
            window.open(res.data.path, "_self");
          } else {
            this.setData({ name: "liveHistory", data: res?.data || {} });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          if (payload.is_download === 1) {
            this.setData({ name: "CsvComp", data: null });
          }
        });
    },
    async getLiveRefData(payload) {
      await axiosClient
        .post("/users/live_table_data_By_Id", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "liveRefData", data: res.data });
            this.setData({ name: "livePopupClose", data: false });
          } else if (res.data.status === 404 && res.data.data.length === 0) {
            this.setData({ name: "livePopupClose", data: true });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getLiveHistoryTableDelete(payloadDelete) {
      await axiosClient
        .post("/users/del_table", payloadDelete)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "deletetable", data: res.data });
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getTableTrackHistory(payload) {
      await axiosClient
        .post("/users/play_table_track", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "tableTrack", data: res.data });
          } else if (payload.is_download === 1) {
            // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
            window.open(res.data.path, "_self");
          } else {
            this.setData({ name: "tableTrack", data: res?.data || {} });
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          if (payload.is_download === 1) {
            this.setData({ name: "CsvComp", data: null });
          }
        });
    },
    async getviewTable(payload) {
      await axiosClient
        .post("/users/tableid_data", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "tracktable", data: res.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getviewchipHistory(payload) {
      await axiosClient
        .post("/chip_history/chip_history_module1", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "chipHistory", data: res.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getviewcashHistory(payload) {
      await axiosClient
        .post("/cash_history/cash_history_module1", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "chipHistory", data: res.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default statisticsModel;
