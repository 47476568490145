import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const botModel = {
    state: {
        count: null,
        data: [],
        pageData: { page: "0", perpage: "100", IsBlock: false, is_download: "0", search: '' },
        // botData: []
    },
    reducers: {
        setBotData(state, payload) {
            return { ...state, data: { ...payload }, count: payload.count };
        },
        setUpdatePageData(state, payload) {
            return { ...state, pageData: { ...payload } };
        }
    },
    effects: (dispatch) => ({
        async getBotData(payload) {
            await axiosClient.post('/dashboard/user_data_boat', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getBotData({ ...payload, page: `${+payload.page - 1}` });
                        return;
                      }
                    this.setUpdatePageData(payload);
                    this.setBotData(res.data);
                    // this.setData({ name: "tableTrack", data: res.data });
                }
                else if (payload.is_download === 1) {
                    window.open(res.data.path, '_self')
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getLiveBotData(payload) {
            await axiosClient.post('/bots/current_play', payload).then(res => {
                if (res.data.status === 200) {
                    this.setUpdatePageData(payload);
                    this.setBotData(res.data);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getSearchRecords(payload) {
            const params = new URLSearchParams(payload.formData);
            await axiosClient.post(`/bots/search_bot?${params.toString()}`, payload.data).then(res => {
                if (res.data.status === 200) {
                    this.setUpdatePageData(payload.data);
                    this.setBotData(res.data);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async addBot(payload, state) {
            await axiosClient.post(`/bots/create`, payload).then(res => {
                if (res.data.status === 200) {
                    this.getBotData(state.botModel.pageData);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
            // .finally(() => {
            //     dispatch.modalModel.setModalData()
            // }
            // )
        },
        async updateBot(payload, state) {
            await axiosClient.post(`/bots/edit_bots`, payload).then(res => {
                if (res.data.status === 200) {
                    this.getBotData(state.botModel.pageData);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
            // .finally(() => {
            //     dispatch.modalModel.setModalData()
            // })
        },
        async deleteBot(payload, state) {
            await axiosClient.post(`/bots/remove_bots`, payload).then(res => {
                if (res.data.status === 200) {
                    this.getBotData(state.botModel.pageData);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async resetBot(payload, state) {
            await axiosClient.post(`/bots/reset`, payload).then(res => {
                if (res.data.status === 200) {
                    this.getBotData(state.botModel.pageData);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async bulkUpload(payload, state) {
            await axiosClient.post(`/bots/bulk_upload`, payload).then(res => {
                if (res.data.status === 200) {
                    this.getBotData(state.botModel.pageData);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
    }),
};

export default botModel;