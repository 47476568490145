import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const TdsDataModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
        async addTdsData(payload) {
            await axiosClient.post('/content/up_tds', payload).then(res => {
                if (res.data.status === 200) {
                    // getBalanceData(payload)
                    this.getTdsDataShow(payload)
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTdsDataShow(payload) {
            await axiosClient.post('/content/tds', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "tds", data: res.data});
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        }
    }),
};

export default TdsDataModel;