import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import CSVDownload from "react-csv/src/components/Download";
import moment from "moment";

const withdrawrequestModel = {
    state: {
        withDraw: {},
        CsvComp: null,
        pageInfo: { page: "0", perpage: "10" }
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({
        async addWithdrawData(payload) {
            await axiosClient.post('/users/up_withdraw_request', payload).then(res => {
                if (res.data.status === 200) {
                    // getBalanceData(payload)
                    this.addWithdrawData(payload)
                    // this.setData(res.data);
                    // this.setData({ name: "withdraw", data: res.data.data });

                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getWithdrawData(payload) {
            await axiosClient.post('/users/withdraw_status', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getWithdrawData({...payload,page: `${+payload.page - 1}` });
                        return;
                    }
                    this.setData({ name: "withDraw", data: res.data });
                }
                else if (payload.is_download === "1") {
                    window.open(res.data.path, '_self')
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                    // this.setData({ name: payload.status ==="PROCESSING"?"CsvComppp":payload.status ==="SUCCESS"?"CsvComps":payload.status ==="REJECTED"?"CsvCompr" :'', data: <CSVDownload data={res.data} target="_blank" /> });
                }
                else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async moveToPending(payload) {
            await axiosClient.post('/users/withdraw_move_to_pending', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "withDraw", data: res.data });
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getWithdrawViewData(payload) {
            await axiosClient.post('/users/userwithdraw', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "userWithdraw", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getWithdrawHistory(payload) {
            await axiosClient.post('/users/getCsvData', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "withDraw", data: res.data });
                } else if (payload.is_download === 1) {
                    window.open(res.data.path, '_self')
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setData({ name: "withDraw", data: res?.data || {} });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setData({ name: "CsvComp", data: null });
                }
            })
        },
        async getBlockUser(payload) {
            const { AcceptUser, pageInfo, check, formData } = payload;
            await axiosClient.post(`/users/withdraw_request_action`, AcceptUser).then(res => {
                if (res.data.status === 200) {
                    this.getWithdrawData(pageInfo);
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async acceptWithdraw(payload) {
            const { AcceptUser, pageInfo, check, formData } = payload;
            await axiosClient.post(`/users/withdraw_razorpay `, AcceptUser).then(res => {
                if (res.data.status === 200) {
                    this.getWithdrawData(pageInfo);
                    if (res?.data?.data?.userPayoutError.length > 0) {
                        res?.data?.data?.userPayoutError.map((data, index) => {
                            customNotification(data?.error?.description, "error")
                        })
                    }
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getWithdrawListCounter(payload) {
            await axiosClient.post('/document/counter', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: payload.status === "PENDING" ? "userWithdrawListCounter" : payload.status === "SUCCESS" ? "userWithdrawListCounter2" : payload.status === "REJECTED" ? "userBankListCounter3" : '', data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async withdraw_activity(withdraw) {
            const { data, payload } = withdraw
            await axiosClient.post('/users/withdraw_activity', data).then(res => {
                if (res.data.status === 200) {
                    this.getWithdrawData(payload)
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },

        async checkStatus(payload) {
            await axiosClient.post('/webhook/checkStatus', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "checkStatus", data: res.data });
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error");
            });
        }
    }),
};

export default withdrawrequestModel;