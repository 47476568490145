import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const balancePageModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
        async addBalanceData(payload) {
            await axiosClient.post('/content/up_balance', payload).then(res => {
                if (res.data.status === 200) {
                    // getBalanceData(payload)
                    this.getBalanceDataShow(payload)
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getBalanceDataShow(payload) {
            await axiosClient.post('/content/balance_data', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "balance", data: res.data });
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "users", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
    }),
};

export default balancePageModel;