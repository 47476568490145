import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
// import persistPlugin from "@rematch/persist";
// import storage from "redux-persist/lib/storage";
import userModel from "../model/user.model";
import categoryModel from "../model/category.model";
import modalModel from "../model/modal.model";
import botModel from "../model/bot.model";
import feedbackModel from "../model/feedbackModel";
import reportProblemModel from "../model/reportProblem.model";
import reportModel from "../model/report.model";
import preConfigModel from "../model/preConfig.model";
import levelOptionModel from "../model/levelOption.model";
import dailyRewardModel from "../model/dailyReward.model";
import featureRollOutModel from "../model/featureRollOut.model";
import androidModel from "../model/androidModel";
import statisticsModel from "../model/statisticsModel";
import userPageModel from "../model/userPage.model";
import blockUserModel from '../model/blockUser.model'
import dashboardModel from "../model/dashboard.model";
import documentListModal from "../model/documentList.model";
import bankpanListModel from "../model/bankpanList.model";
import balancePageModel from '../model/balancePage.model';
import addCaseStateMentModel from '../model/addCaseStateMent.model'
import requestWithdrawModel from '../model/requestWithdraw.model';
import kycModel from '../model/kyc.model';
import TdsDataModel from '../model/tds.model';
import playResponsibilityModel from '../model/playResponsibility.model';
import ratingFeedbackModel from '../model/ratingFeedback.model';
import securityLogoutModel from '../model/securityLogout.model';
import bannerModel from '../model/banner.model';
import withdrawrequestModel from '../model/withdrawRequest.model'
import bonusModel from '../model/bonus.model';
import userBalance from '../model/userBalance.model'
import userAddcaseStatment from '../model/userAddcaseStatment.model'
import userPersonalDetails from '../model/userPersonalDetails.model'
import userTDS from '../model/useTDS.model';
import useplayResponsibility from '../model/useplayResponsibility.model'
import userAddAmount from '../model/userAddAmount.model'
import useGameSummery from '../model/useGameSummery.model'
import LocationModel from '../model/location.model'
import UpiListModel from '../model/UpiList.model'
import dashboardUser from '../model/dashboardUser.model'
import subAdminModel from '../model/subAdminModel';
import forgotPasswordModel from '../model/forgotPassword.model';
import invoicemodel from '../model/invoice.model'
import querymodel from '../model/query.model';
import playApkModel from '../model/playApk.model';
import userProfile from '../model/userProfile';
import leaderBoardModel from '../model/leaderBoard.model';
import ipAddressModel from '../model/ipAddress.model';
import raiseIssueModel from '../model/raiseIssue.model';

const loadingPlugin = createLoadingPlugin();
// const persistPlugin = createPersistPlugin({
//     storage,
//     whitelist: ['bannerModel'],
// })
// const persistConfig = {
//     key: "root",
//     storage,
//     whitelist: ['bannerModel'],
// };
const store = init({
    redux: {
        rootReducers: {
            RESET: (state, action) => {
            },
        },
    },
    plugins: [loadingPlugin,
        // persistPlugin(persistConfig)
    ],
    models: {
        userModel, categoryModel, modalModel, botModel, feedbackModel, reportProblemModel, reportModel,
        preConfigModel, levelOptionModel, featureRollOutModel, dailyRewardModel, androidModel, statisticsModel,
        userPageModel, blockUserModel, dashboardModel, documentListModal, bankpanListModel, balancePageModel,
        addCaseStateMentModel, requestWithdrawModel, kycModel, TdsDataModel, playResponsibilityModel, ratingFeedbackModel,
        securityLogoutModel, bannerModel, withdrawrequestModel, bonusModel, userBalance, userAddcaseStatment,
        userPersonalDetails, userTDS, useplayResponsibility, userAddAmount, useGameSummery, LocationModel, UpiListModel,
        dashboardUser, subAdminModel,forgotPasswordModel, invoicemodel, querymodel, playApkModel, userProfile, leaderBoardModel,
        ipAddressModel, raiseIssueModel
    }
});

export default store