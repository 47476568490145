import axiosClient from "../../api/axiosClient";
import React from "react";
import CSVDownload from "react-csv/src/components/Download";
import customNotification from "../../components/notification";

const feedbackModel = {
    state: {
        data: [],
        CsvComp: null,
        count:0,
    },
    reducers: {
        setFeedbackData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        }
    },
    effects: (dispatch) => ({
        async getFeedbackData(payload) {
            await axiosClient.post('/feedback', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getFeedbackData({ ...payload, page: `${+payload.page - 1}` });
                        return;
                    }
                    this.setFeedbackData({ name: "count", data: res.data.count });
                    this.setFeedbackData({ name: "data", data: res.data.data });
                } else if (payload.is_download === 1 && !res.data.status) {
                    window.open(res.data.path, '_self')
                } else {
                    this.setFeedbackData({ name: "data", data: [] });
                    // customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error");
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setFeedbackData({ name: "CsvComp", data: null });
                }
            })
        },
    }),
};

export default feedbackModel;