import React from 'react'
import right_side from '../../assets/img/right_side.png'
import left_side from '../../assets/img/left_side.png'
import arrow_back from '../../assets/img/arrow_back.png'

function PageNotFound({ handleNavigate }) {
    const navigate = () => {
        handleNavigate('/dashboard')
        location.reload()
    }
    return (
        <div className='error-page'>
            <div>
                <img src={left_side} alt="left" />
            </div>
            <div className='error-page_font'>
                <div>
                    <h1>404</h1>
                    <h2>Page not found!</h2>
                </div>
                {/* <p onClick={navigate}><img src={arrow_back} alt="" />Go to homepage</p> */}
            </div>
            <div>
                <img src={right_side} alt="right" />
            </div>
        </div>
    )
}

export default PageNotFound
