import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const querymodel = {
  state: {
    queryData: {},
    queryPenData: {},
    bonus: {},
    userBonus: {},
    userStatement: {},
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
  },

  effects: (dispatch) => ({
    async queryTable(payload) {
      await axiosClient
        .post("/users/query_upload", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "queryData", data: res.data });
            dispatch.querymodel.queryPenTable({
              is_download: "0",
              page: "0",
              perpage: "100",
              isNotificationRead: false,
              status: "PENDING",
            });
            customNotification(res?.data?.message, "success");
          } else {
            customNotification(res?.data?.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async queryPenTable(payload) {
      await axiosClient
        .post("/users/queryDetail", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.queryPenTable({ ...payload, page: `${+payload.page - 1}` });
              return;
            }
            this.setData({ name: "queryPenData", data: res.data });
          } else if (payload.is_download === 1) {
            window.open(res.data.path, "_self");
          } else {
            // customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async queryAccept(payload) {
      await axiosClient
        .post("/users/queryAction", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "queryAccept", data: res.data });
            customNotification(res.data.message, "success");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async bonusGet(payload) {
      await axiosClient
        .post("/users/bonus_module", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "bonus", data: res.data });
          } else if (payload.is_download === 1) {
            window.open(res.data.path, "_self");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async bonusView(payload) {
      await axiosClient
        .post("/users/bonus_module1", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "userBonus", data: res.data });
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async statementModel(payload) {
      await axiosClient
        .post("/users/bonus_mini_statement", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "userStatement", data: res.data });
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default querymodel;
