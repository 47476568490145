import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const kycModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
        async addKycData(payload) {
            await axiosClient.post('/content/up_kyc', payload).then(res => {
                if (res.data.status === 200) {
                    // getBalanceData(payload)
                    this.getKycDataShow(payload)
                    customNotification(res.data.message, "success") 
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getKycDataShow(payload) {
            await axiosClient.post('/content/kyc', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "kyc", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        }
    }),
};

export default kycModel;