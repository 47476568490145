import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./rematch/store";
import { BrowserRouter } from "react-router-dom";
import history from "./history";
import '../src/assets/scss/app.scss';
import Loader from "./components/Loader";
// import { getPersistor } from "@rematch/persist";
// import { PersistGate } from "redux-persist/lib/integration/react";
//import 'antd/dist/antd.css';
// const persistor = getPersistor();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
