import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const dashboardModel = {
    state: {
        onlineUsers: [],
        playingUsers: [],
        dailyActive: [],
        monthlyActive: [],
        newUsers: [],
        realtimeUser: [],
        totalPlayers: [],
        getTotalDownloads: [],
        lastActivity: ''
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: [...(state[payload.name] || []), payload.data] };
        },
        setRealtimeData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
        lastActivity(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({
        async getCurrentOnlineUsers() {
            await axiosClient.post('/current_online_users', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "onlineUsers", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getCurrentPlayingUsers() {
            await axiosClient.post('/current_playing_user', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "playingUsers", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getDailyActiveUsers() {
            await axiosClient.post('/daily_active_user', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "dailyActive", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getMonthlyActiveUsers() {
            await axiosClient.post('/monthly_active_user', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "monthlyActive", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getNewUsersNotPlayed() {
            await axiosClient.post('/new_user_not_played', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "newUsers", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getRealtimeUsers() {
            await axiosClient.post('/real_time_high_users', { is_download: "0" }).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "realtimeUser", data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalPlayers() {
            await axiosClient.post('/dashboard/total_player_list').then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totalPlayers", data: res.data });
                } else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalDownloads(payload) {
            await axiosClient.post('/dashboard/total_download', payload).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totalDownloads", data: res.data });
                } else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotaltransaction(payload) {
            await axiosClient.post('/dashboard/total_transaction', payload).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totalTransaction", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotaltransactiontT(payload) {
            await axiosClient.post('/dashboard/total_transaction1', payload).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totalTransaction", data: res.data });
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalWithdraw(payload) {
            await axiosClient.post('/dashboard/total_withdraw', payload).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totalWithdraw", data: res.data });
                } else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalTds(payload) {
            await axiosClient.post('/dashboard/total_tds', payload).then(res => {
                if (res.data.status === 200) {
                    this.setRealtimeData({ name: "totaltds", data: res.data });
                } else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async userLastActivity(payload) {
            this.lastActivity({ name: "lastActivity", data: payload });
        },
    }),
};

export default dashboardModel;