import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";
// import CSVDownload from "react-csv/src/components/Download";
const userPageModel = {
    state: {
        users: {
            data: []
        },
        PWError: false,
        checkPassword: {}
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
        setUpdatePageData(state, payload) {
            return { ...state, pageData: { ...payload } };
        },
        // setPWError(state,payload){
        //     return{ ...state, [payload.name]:payload.error}
        // }
    },
    effects: (dispatch) => ({
        async getUserData(payload) {
            await axiosClient.post('/dashboard/user_data_table', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "users", data: res.data });
                }
                else if (payload.is_download === "1") {
                    window.open(res.data.path, '_self')
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                }
                else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "users", data: res.data });
                    // this.getUserData(payload)
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
        async getSuspendData(payload) {
            await axiosClient.post(`/users/suspend_user`, payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "users", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
        async getCashAmount(payload, state) {
            const { amount, pageInfo } = payload;
            await axiosClient.post(`/users/add_cash`, amount).then(res => {
                if (res.data.status === 200) {
                        // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getCashAmountRemove(payload, state) {
            const { amountR, pageInfo } = payload;
            await axiosClient.post(`/users/remove_add_cash`, amountR).then(res => {
                if (res.data.status === 200) {
                    // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getBonusAmount(payload, state) {
            const { bonus, pageInfo } = payload;
            await axiosClient.post(`/users/add_bonus`, bonus).then(res => {
                if (res.data.status === 200) {
                    // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getBonusAmountRemove(payload, state) {
            const { bonusR, pageInfo } = payload;
            await axiosClient.post(`/users/Remove_bonus`, bonusR).then(res => {
                if (res.data.status === 200) {
                    // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getWinningAmount(payload, state) {
            const { winning, pageInfo } = payload;
            await axiosClient.post(`/users/add_winning`, winning).then(res => {
                if (res.data.status === 200) {
                    // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getWinningAmountRemove(payload, state) {
            const { winningR, pageInfo } = payload;
            await axiosClient.post(`/users/remove_winning`, winningR).then(res => {
                if (res.data.status === 200) {
                    // this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getBlockUser(payload, state) {
            const { blockUser, pageInfo, check, formData } = payload;
            await axiosClient.post(`/dashboard/block_user `, blockUser).then(res => {
                if (res.data.status === 200) {
                    check ? this.searchData({ ...formData, data: pageInfo }) : this.getUserData(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getPasswordAmount(payload) {
            await axiosClient.post(`/users/check_internal_password`, payload).then(res => {
                // if (res.data.status === 200) {
                this.setData({ name: "checkPassword", data: res.data });
                // }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getNewPassword(payload, state) {
            const { password, pageInfo } = payload;
            await axiosClient.post(`/users/create_internal_password`, password).then(res => {
                if (res.data.status === 200) {
                    this.getUserData(pageInfo);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getviewAmountShow(payload) {
            await axiosClient.post('/users/account_statement_module ', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "viewAmount", data: res.data });

                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getQuery(payload) {
            await axiosClient.post('/users/query_upload', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "query", data: res.data });
                    customNotification(res?.data?.message, "success")
                }
                else{
                    customNotification(res?.data?.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
    }),
};

export default userPageModel;