import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const preConfigModel = {
  state: {
    errorCode: [],
    chatData: [],
    config: "{}",
    count: 0,
    pageData: { page: "0", perpage: "100", search: "" },
    schema:{   }
  },
  reducers: {
    setConfigData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },
  },
  effects: (dispatch) => ({
    async getChatData() {
      await axiosClient
        .post("/template_chat_manager")
        .then((res) => {
          if (res.data.status === 200) {
            this.setConfigData({ name: "chatData", data: res.data.data });
          } else {
            this.setConfigData([]);
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getConfigData() {
     return await axiosClient
        .get("/config/getConfig")
        .then((res) => {
          if (res.data.status === 200) {
            let property = Object.entries(res.data.dis[0]).reduce((acc,cur) => {
              return {
                property:{...acc.property,[cur[0]]:{title:cur[1]}}
              }
            },{property:{}})
            let schema = {
              title: 'Game config',
              type: 'object',
              properties:{...property.property}
            };
            this.setConfigData({
              name: "config",
              data: JSON.stringify(res.data.data[0], null, 2),
            });
            this.setConfigData({
              name: "schema",
              data: schema,
            });
          } else {
            this.setConfigData([]);
            customNotification(res.data.message, "info");
          }
          return res.data.data[0]
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async createChatMessage(data) {
      const { payload, handleCancel } = data;
      const { Messages, ...rest } = payload;
      await axiosClient
        .post("/template_chat_manager/insert", { ...rest, messages: Messages })
        .then((res) => {
          if (res.data.status === 200) {
            this.getChatData();
            handleCancel();
          } else {
            this.getChatData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async deleteChatMessage(payload) {
      await axiosClient
        .post("/template_chat_manager/delete", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getChatData();
          } else {
            this.getChatData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async updateChatMessage(data) {
      const { payload, handleCancel } = data;
      const { Messages, ...rest } = payload;
      await axiosClient
        .post("/template_chat_manager/update", { ...rest, messages: Messages })
        .then((res) => {
          if (res.data.status === 200) {
            this.getChatData();
            handleCancel();
          } else {
            this.getChatData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async updateConfigData(payload) {
      await axiosClient
        .post("/config/saveConfig", payload)
        .then((res) => {
          this.getConfigData();
          if (res.data.status === 200) {
            customNotification(res.data.message, "success");
          } else {
            // this.getConfigData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getErrorCodeData(_, state) {
      const payload = state.preConfigModel.pageData;
      await axiosClient
        .post("/pre_config/error_code", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.getErrorCodeData();
              return;
            }
            this.setConfigData({ name: "errorCode", data: res.data.data });
            this.setConfigData({ name: "count", data: res.data.count });
          } else {
            this.setConfigData([]);
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async createErrorCode(data) {
      const { formData, handleCancel } = data;
      const { Messages, ...rest } = formData;
      await axiosClient
        .post("/pre_config/error_code_insert", { ...rest, messages: Messages })
        .then((res) => {
          if (res.data.status === 200) {
            this.getErrorCodeData();
            customNotification(res.data.message, "success");
          } else {
            this.getErrorCodeData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          handleCancel();
        });
    },
    async updateErrorCode(data) {
      const { errorData, handleCancel } = data;
      const { Messages, ...rest } = errorData;
      await axiosClient
        .post("/pre_config/error_code_updates", { ...rest, messages: Messages })
        .then((res) => {
          if (res.data.status === 200) {
            this.getErrorCodeData();
            customNotification(res.data.message, "success");
            handleCancel();
          } else {
            this.getErrorCodeData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async deleteErrorCode(payload) {
      await axiosClient
        .post("/pre_config/error_code_delete", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getErrorCodeData();
            customNotification("Data deleted successfully", "success");
          } else {
            this.getErrorCodeData();
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
  }),
};

export default preConfigModel;
