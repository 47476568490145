import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const bannerModel = {
    state: {
        pageData: { page: "0", perpage: "10" }
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data, ...payload };
        },
    },
    effects: (dispatch) => ({
        async addBannerData(payload) {
            await axiosClient.post('/banner/up_banner', payload).then(res => {
                if (res.data.status === 200) {
                    // getBalanceData(payload)
                    // this.setData(payload );
                    this.getBannerDataShow(payload)
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "success")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getBannerDataShow(payload) {
            await axiosClient.post('/banner/banner_list', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "bannerData", data: res.data });
                    localStorage.setItem('bannerDetail', JSON.stringify(res.data.data[0]));
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async deleteBanner(payload, state) {
            let { _id } = payload;
            await axiosClient.post(`/banner/del_banner`, { "banner_id": _id }).then(res => {
                if (res.data.status === 200) {
                    this.getBannerDataShow(state.bannerModel.pageData);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
    }),
};

export default bannerModel;