import { PrivateRoutesArr, PublicRoutesArr } from "../routers/navList";
import { Route } from "react-router-dom";
import PublicRoutes from "../routers/public";
import React from "react";
import PrivateRouters from "../routers/private";
import Loader from "../components/Loader";

const DefaultLayout = React.lazy(() => import('../components/defaultLayout'));

export const publicRouter = (handleNavigate) => {
    return (
        PublicRoutesArr.map(({ component: Component, path }, key) => {
            return <Route
                path={path}
                element={
                    <PublicRoutes>
                        <React.Suspense fallback={<Loader />}>
                            <Component handleNavigate={handleNavigate} />
                        </React.Suspense>
                    </PublicRoutes>
                }
                key={key}
            />
        })
    )
};

export const privateRouter = (handleNavigate) => {
    return (
        PrivateRoutesArr.map(({ component: Component, path, name }, key) => (
            <Route
                path={path}
                element={
                    <PrivateRouters>
                        <DefaultLayout handleNavigate={handleNavigate} title={name}>
                            <React.Suspense fallback={<Loader />}>
                                <Component handleNavigate={handleNavigate} />
                            </React.Suspense>
                        </DefaultLayout>
                    </PrivateRouters>
                }
                key={key}
            />
        ))
    )
};