import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const userAddAmount = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data, ...payload };
        },
    },
    effects: (dispatch) => ({
        // async addBalanceData(payload) {
        //     await axiosClient.post('/content/up_balance', payload).then(res => {
        //         if (res.data.status === 200) {
        //             // getBalanceData(payload)
        //             this.getBalanceDataShow(payload)
        //             customNotification(res.data.message, "success")
        //         } else {
        //             customNotification(res.data.message, "info")
        //         }
        //     }).catch(err => {
        //         customNotification(err.message, "error")
        //     })
        // },
        async getAddAmountShow(payload) {
            await axiosClient.post('/users/account_statement_module_all ', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getAddAmountShow({ ...payload, page: `${+payload.page - 1}` });
                        return;
                      }
                    this.setData({ name: "addAmount", data: res.data });

                } else if (payload.is_download === 1) {
                    window.open(res.data.path, '_self')
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getviewAmountShow(payload) {
            await axiosClient.post('/users/account_statement_module ', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "viewAmount", data: res.data });

                }
                else if (payload.is_download === "1") {
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                    // const url = window.URL.createObjectURL(new Blob([res.data.path]));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', `${Date.now()}.xlsx`);
                    // document.body.appendChild(link);
                    // link.click();
                    window.open(res.data.path, '_self')
                }
                else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "addAmount", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
    }),
};

export default userAddAmount;