import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const forgotPasswordModel = {
    state: {
        passwordVerify: [],
        addPasswordVerify: {},
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        }
    },
    effects: (dispatch) => ({
        async passwordInputVerify(payload) {
            await axiosClient.post('/forgetpassword1/step1', payload).then(res => {
                this.setData({ name: "passwordVerify", data: res.data });
                if(res.data.status !== 200){
                    customNotification(res.data.message, "error")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async passwordUpdate(payload) {
            await axiosClient.post('/forgetpassword1/step2', payload).then(res => {
                // this.setData({ name: "passwordUpdate"});
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async addPasswordUpdate(payload) {
            await axiosClient.post('/users/change_password', payload).then(res => {
                this.setData({ name: "addPasswordVerify", data: res.data });
                if (res?.data?.status === 200) {
                    customNotification(res.data.message, "success")
                }
                if (res?.data?.status === 401) {
                    customNotification(res.data.message, "error")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
    })
}

export default forgotPasswordModel