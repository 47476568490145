import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const blockUserModel = {
    state: {
        users: {}
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        }
    },
    effects: (dispatch) => ({
        async getBlockUserData(payload) {
            await axiosClient.post('/users/block_list', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "BlockUser", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getBlockUser(payload, state) {
            const { blockUser, pageInfo } = payload;
            await axiosClient.post(`/users/block_list `, blockUser).then(res => {
                if (res.data.status === 200) {
                    this.getBlockUserData(pageInfo);
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getUserData(payload) {
            const { blockUser, pageInfo } = payload;
            await axiosClient.post(`/dashboard/block_user`, blockUser).then(res => {
                if (res.data.status === 200) {
                    this.getBlockUserData(pageInfo);
                    customNotification(res.data.message, "success")
                  } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
       
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "BlockUser", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
        // async getSuspendData(payload) {
        //     await axiosClient.post(`/users/suspend_user`, payload).then(res => {
        //         if (res.data.status === 200) {
        //             this.setData({ name: "users", data: res.data });
        //         } else {
        //             customNotification(res.data.message, "info")
        //         }
        //     }).catch(err => {
        //         customNotification(err.message, "error")
        //     });
        // },
        // async getCashAmount(payload, state) {
        //     const { amount, pageInfo } = payload;
        //     await axiosClient.post(`/users/add_cash`, amount).then(res => {
        //         if (res.data.status === 200) {
        //             this.getUserData(pageInfo);
        //         } else {
        //             customNotification(res.data.message, "info")
        //         }
        //     }).catch(err => {
        //         customNotification(err.message, "error")
        //     }).finally(() => {
        //         dispatch.modalModel.setModalData()
        //     })
        // },
    
    }),
};

export default blockUserModel;