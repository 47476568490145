const modalModel = {
    state: {
        visible: false,
        Component: <div>no content</div>
    },
    reducers: {
        setModalData(state, payload) {
            return { ...state, visible: !state.visible, Component: payload || state.Component} ;
        },
    },
    effects: () => ({
    }),
};

export default modalModel;