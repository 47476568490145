import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const dailyRewardModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return [...payload];
        }
    },
    effects: (dispatch) => ({
        async getDailyReward() {
            await axiosClient.post('/daily_reward_manager').then(res => {
                if (res.data.status === 200) {
                    this.setData(res.data.data);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async updateLevelOption(payload) {
            await axiosClient.post('/daily_reward_manager/update', payload).then(res => {
                if (res.data.status === 200) {
                    this.getDailyReward();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
    }),
};

export default dailyRewardModel;