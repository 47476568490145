import { notification } from "antd";

const customNotification = (msg, type) => {
    const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

    if (capitalizedType === "Success") {
        return notification.success({
            className: "custom-toast",
            message: capitalizedType,
            description: msg
        });
    } else if (capitalizedType === "Info") {
        return notification.info({
            className: "custom-toast",
            message: capitalizedType,
            description: msg
        });
    } else if (capitalizedType === "Error") {
        return notification.error({
            className: "custom-toast",
            message: capitalizedType,
            description: msg
        });
    } else {
        return notification.warning({
            className: "custom-toast",
            message: capitalizedType,
            description: msg
        });
    }
};

export default customNotification;
