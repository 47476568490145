import Cookies from 'universal-cookie';
import React, { PureComponent } from "react";
import html2canvas from 'html2canvas';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';
import CryptoJS from 'crypto-js';

const cookies = new Cookies();

export const getAccessToken = () => cookies.get('access_token') ? cookies.get('access_token') : sessionStorage.getItem('access_token');
export const isAuthenticated = () => !!getAccessToken();

export const expireTime = () => {
    return new Date(Date.now() + 12 * (60 * 60 * 1000));
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export function arrayOfRange(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export default class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

//Creating dynamic link that automatically click
function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
    //after creating link you should delete dynamic link
    //clearDynamicLink(link);
}

export function PrintDiv(div, type, id, ext) {
    html2canvas(div).then(canvas => {
        let myImage = canvas.toDataURL(type);
        downloadURI("data:" + myImage, `${id}${ext}`);
    });
}

function ImagetoPrint(source) {
    return "<html><head><scri" + "pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" + "pt></head><body onload='step1()'>\n" +
        "<img src='" + source + "' /></body></html>";
}

export function printImage(source) {
    html2canvas(source).then(canvas => {
        let myImage = canvas.toDataURL("image/png");
        let pwa = window.open(window.location.origin, "prithvi-rummy", "pop");
        pwa.document.open();
        pwa.document.write(ImagetoPrint(myImage));
        pwa.document.close();
    });
}

export const getEditorState = (html) => {
    const blocksFromHtml = htmlToDraft(html || "<p></p>");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    return EditorState.createWithContent(contentState);
};

export const decrypt = (encrypteddata) => {
    var bytes = CryptoJS.AES.decrypt(encrypteddata, 'HaHDadHAERKasdflk234sdfskjfhAAAHdsferPSEdropsakjdfhsdfhjksdfSDHFwerASLdhfwerHASDFWerowisjasdfoisadfosahHSDf');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}
 
export const millisecondsToTime = (milliseconds) => {
    if (milliseconds <= 0) {
      return '00:00:00'; 
    }
  
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedTime;
  }