import axios from "axios";
import Cookies from 'universal-cookie';
import { expireTime } from "../utils";

const cookies = new Cookies();

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// axiosClient.defaults.timeout = 50000;

axiosClient.interceptors.response.use(
    function (response) {
        if (response.data.status === 501) {
            const remember = localStorage.getItem("remember") === "false" ? false : true
            if (remember == true) {
                cookies.remove('access_token', { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                cookies.remove('u_id', { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
            }
            if (remember == false) {
                sessionStorage.removeItem("access_token")
                sessionStorage.removeItem("u_id")
            }
            localStorage.removeItem("remember")
        }
        if (response.data.status === 401) {
            const msg = response.data.message.toLowerCase() 
            if (msg.includes('permission')) {
                window.location.href = `${window.location.origin}/dashboard`
            }
        }
        return response;
    },
    function (error) {
        let res = error.response;
        if (res?.status === 401) {
            window.location.href = process.env.REACT_APP_BASE_URL;
        }
        console.error("Looks like there was a problem. Status Code:" + res?.status);
        return Promise.reject(error);
    }
);

axiosClient.interceptors.request.use(async function (config) {
    const token = cookies.get('access_token') ? cookies.get('access_token') : sessionStorage.getItem('access_token');
    config.headers.authorization = token || '';
    config.headers.ipAddress = localStorage.getItem("ipAdd")

    if (config.url === "/banner/banner_upload") {
        config.headers['content-type'] = 'multipart/form-data'
    }
    //    if(config.url==="/dashboard/user_data_table"){
    //     config.headers['responseType']= 'arraybuffer'
    //    }

    // config.headers['Access-Control-Allow-Headers'] = "X-Requested-With,content-type,authorization";
    // config.headers['Access-Control-Allow-Methods'] = "PUT, GET, POST, DELETE, OPTIONS";
    // config.headers['Access-Control-Allow-Origin'] = "*";
    return config;
});
export default axiosClient;