import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { privateRouter, publicRouter } from "./utils/routers";
import CustomModal from "./components/CustomModal";
import { useSelector } from "react-redux";
import store from "./rematch/store";
import ChangePassword from "./pages/ChangePassword";
import PrivateRouters from "./routers/private";
import { notification } from "antd";
import { expireTime } from './utils';
import Cookies from 'universal-cookie';
import PageNotFound from './pages/404';
import { useEffect } from 'react';

const ForgotPassword = React.lazy(() => import('./pages/authantication/ForgotPassword'));

const App = () => {
    const cookies = new Cookies();
    const remember = localStorage.getItem("remember") === "false" ? false : true
    const modal = useSelector(state => state.modalModel);
    const { visible, Component } = modal;
    const { dispatch } = store;
    let navigate = useNavigate();


    const handleNavigate = (link) => {
        navigate(link);
    };

    const checkIpAddress = () => {
        const remoteIpAddress = '43.205.22.163'; // Replace with the IP address you want to check
        const userIpAddress = window.location.hostname;
        return userIpAddress === remoteIpAddress;
    };

    const fetchIpAddress = async () => {
        let ip = await fetch("https://geolocation-db.com/json/").then(response => response.json()).then(data => {
            return data?.IPv4
        })
        localStorage.setItem("ipAdd", ip)
    }
    useEffect(() => {
        try {
            fetchIpAddress()
        } catch (error) {
            console.log("error::",error)
        }
    }, [])

    // useEffect(() => {
    //     const handleContextMenu = (event) => {
    //         event.preventDefault();
    //     };
    //     document.addEventListener('contextmenu', handleContextMenu);
    //     return () => {
    //         document.removeEventListener('contextmenu', handleContextMenu);
    //     };
    // }, []);

    notification.config({
        placement: 'topRight',
        bottom: 50,
        duration: 2,
        ltr: true,
        maxCount: 1
    });

    // const useUnload = fn => {
    //     const cb = React.useRef(fn);
    //     React.useEffect(() => {
    //         const onUnload = cb.current;
    //         window.addEventListener('beforeunload', onUnload);
    //         return (event) => {
    //             window.removeEventListener('beforeunload', onUnload);
    //             event.preventDefault();
    //         };
    //     }, [cb]);
    // };
    // useUnload(e => {
    //     e.preventDefault();
    //     handleLogOut();
    //     // const remember = localStorage.getItem("remember") === "false" ? false : true
    //     // if (remember === false) {
    //     // }
    // })
    // const handleLogOut = () => {
    //     cookies.remove('access_token', { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
    //     cookies.remove('u_id', { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
    //     handleNavigate('/');
    //     localStorage.removeItem("remember")
    // };

    // For User Log Out on inactive after 30min
    document.addEventListener("mousemove", () => {
        localStorage.setItem('lastActvity', new Date())
    });
    document.addEventListener("click", () => {
        localStorage.setItem('lastActvity', new Date())
    });
    document.addEventListener("keypress", () => {
        localStorage.setItem('lastActvity', new Date())
    });

    let timeInterval = setInterval(() => {
        let lastAcivity = localStorage.getItem('lastActvity')
        var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
        var seconds = Math.floor((diffMs / 1000));
        var minute = Math.floor((seconds / 60));
        //code for logout
        if (minute === 30 && remember === false) {
            sessionStorage.removeItem("access_token")
            sessionStorage.removeItem("u_id")
            localStorage.removeItem("remember")
            handleNavigate('/');
            clearInterval(timeInterval)
        }
    }, 30000)

    // user last activity

    document.addEventListener("click", () => {
        localStorage.setItem('userlastActvity', new Date())
    });
    document.addEventListener("keypress", () => {
        localStorage.setItem('userlastActvity', new Date())
    });

    let usertimeInterval = setInterval(() => {
        let lastAcivity = localStorage.getItem('userlastActvity')
        dispatch.dashboardModel.userLastActivity(lastAcivity)
    }, 1000)
    return (
        <>
            <Routes>
                {checkIpAddress() ? (<Route path="/*" element={<PageNotFound handleNavigate={handleNavigate} />} />) : (<>
                    {publicRouter(handleNavigate)}
                    {privateRouter(handleNavigate)}
                </>)}
                <Route exact path="/forget" name="ForgetPassword" element={<ForgotPassword />} />
                <Route
                    exact
                    path="/change-password"
                    element={
                        <PrivateRouters>
                            <ChangePassword handleNavigate={handleNavigate} />
                        </PrivateRouters>
                    }
                    key={1}
                />
                <Route exact path="/change-password" name="ForgetPassword" element={<ChangePassword />} />
                <Route path="/*" element={<PageNotFound handleNavigate={handleNavigate} />} />
            </Routes>
            <CustomModal className={Component?.props?.reject ? "reject-pop" : "success-pop"} visible={visible} onCancel={() => {
                dispatch.modalModel.setModalData()
                { modal?.Component?.props?.isActivity && dispatch.withdrawrequestModel.withdraw_activity({ data: modal?.Component?.props?.isActivity?.data, payload: modal?.Component?.props?.isActivity?.payload }) }
            }}>
                {Component}
            </CustomModal>
        </>
    )
};

export default App;