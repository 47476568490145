import axiosClient from "../../api/axiosClient";
import CSVDownload from "react-csv/src/components/Download";
import React from "react";
import customNotification from "../../components/notification";

const reportProblemModel = {
    state: {
        data: {
            data: [],
        },
        CsvComp: null
    },
    reducers: {
        setReportProblemData(state, payload) {
            return {...state, [payload.name]: payload.data};
        }
    },
    effects: (dispatch) => ({
        async getReportProblemData(payload) {
            await axiosClient.post('/report_issue', payload).then(res => {
                if (res.data.status === 200) {
                    this.setReportProblemData({name: "data", data: res.data.data});
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportProblemData({name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" />});
                } else {
                    this.setReportProblemData({name: "data", data: []});
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportProblemData({ name: "CsvComp", data: null });
                }
            })
        },
    }),
};

export default reportProblemModel;