import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const playApkModel = {
  state: {
    playPending: [],
    playAction: [],
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
  },
  effects: (dispatch) => ({
    async playPendingData(payload) {
      await axiosClient
        .post("/users/playstore_data_detail", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.playPendingData({ ...payload, page: `${+payload.page - 1}`});
              return;
            }
            this.setData({ name: "playPending", data: res.data });
          } else if (payload.is_download === "1") {
            window.open(res.data.path, "_self");
          } else {
            // customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async playPendingAction(body) {
      const { data, payload } = body;
      await axiosClient
        .post("/users/playstore_data_action", data)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "playAction", data: res.data });
            this.playPendingData(payload);
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default playApkModel;
