import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const featureRollOutModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return [...payload];
        }
    },
    effects: (dispatch) => ({
        async getFeatureRollOut() {
            await axiosClient.post('/feature_roll_out').then(res => {
                if (res.data.status === 200) {
                    this.setData(res.data.data);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async updateFeatureRollOut(payload) {
            await axiosClient.post('/feature_roll_out/feature_rollout_status', payload).then(res => {
                if (res.data.status === 200) {
                    this.getFeatureRollOut();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
    }),
};

export default featureRollOutModel;