import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const androidModel = {
    state: {
        inAppIdList: [],
        promotionOffer: []
    },
    reducers: {
        setData(state, payload) {
            return {...state, [payload.name]: [...payload.data]};
        }
    },
    effects: (dispatch) => ({
        async getAppIdList(payload) {
            let res = await axiosClient.post('/promotional_offer/in_app_id', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: 'inAppIdList', data: res.data.data });
                    return res.data.data;
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
            return res;
        },
        async addAppIdList(payload) {
            await axiosClient.post('/promotional_offer/in_app_id/insert', payload).then(res => {
                if (res.data.status === 200) {
                    this.getAppIdList({ platform: payload.platform });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async updateAppIdList(payload) {
            await axiosClient.post('/promotional_offer/in_app_id/update', payload).then(res => {
                if (res.data.status === 200) {
                    this.getAppIdList({ platform: payload.platform });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async deleteAppIdList(payload) {
            await axiosClient.post('/promotional_offer/in_app_id/delete', { in_app_id: payload.in_app_id }).then(res => {
                if (res.data.status === 200) {
                    this.getAppIdList({ platform: payload.platform });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
        async getPromotionList(payload) {
            await axiosClient.post('/promotional_offer/offer', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: 'promotionOffer', data: res.data.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async addOffer(data) {
            const { payload, handleModal } = data;
            await axiosClient.post('/promotional_offer/offer/insert', payload).then(res => {
                if (res.data.status === 200) {
                    this.getPromotionList({ platform: payload.platform });
                    handleModal();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async updateOffer(data) {
            const { payload, handleModal } = data;
            await axiosClient.post('/promotional_offer/offer/update', payload).then(res => {
                if (res.data.status === 200) {
                    this.getPromotionList({ platform: payload.platform });
                    handleModal();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async deleteOffer(payload) {
            await axiosClient.post('/promotional_offer/offer/delete', { offer_id: payload.offer_id }).then(res => {
                if (res.data.status === 200) {
                    this.getPromotionList({ platform: payload.platform });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
    }),
};

export default androidModel;