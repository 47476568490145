import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const invoicemodel = {
    state: {
        invoiceData: [],
        invoiceDataGet: {}
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },

    },
    effects: (dispatch) => ({
        async invoiceTable(payload) {

            await axiosClient.post('/invoice/get', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.invoiceTable({ ...payload, page: `${+payload.page - 1}` });
                        return;
                      }
                    this.setData({ name: "invoiceData", data: res.data });
                }
                else if (payload.is_download === "1") {
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                    // const url = window.URL.createObjectURL(new Blob([res.data.path]));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', `${Date.now()}.xlsx`);
                    // document.body.appendChild(link);
                    // link.click();
                    window.open(res.data.path, '_self')
                }else{
                    customNotification(res?.data?.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async invoiceTableGet(payload) {
            const { formData, handleNavigate, message } = payload
            await axiosClient.post('/invoice/download', formData)
                .then(res => {
                    if (res.status === 200) {
                        if (message == undefined) {
                            function dataURItoBlob(dataURI) {
                                const byteString = window.atob(dataURI);
                                const arrayBuffer = new ArrayBuffer(byteString.length);
                                const int8Array = new Uint8Array(arrayBuffer);
                                for (let i = 0; i < byteString.length; i++) {
                                    int8Array[i] = byteString.charCodeAt(i);
                                }
                                const blob = new Blob([int8Array], { type: 'application/pdf' });
                                return blob;
                            }
                            const blob = dataURItoBlob(res.data.data);
                            const url = URL.createObjectURL(blob);
                            window.open(url, '_blank');
                        } else if (message == "download") {
                            function dataURItoBlob(dataURI) {
                                const byteString = window.atob(dataURI);
                                const arrayBuffer = new ArrayBuffer(byteString.length);
                                const int8Array = new Uint8Array(arrayBuffer);
                                for (let i = 0; i < byteString.length; i++) {
                                    int8Array[i] = byteString.charCodeAt(i);
                                }
                                const blob = new Blob([int8Array], { type: 'application/pdf' });
                                return blob;
                            }
                            const blob = dataURItoBlob(res.data.data);
                            const url = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${res.data.fileName}`); //or any other extension
                            link.click();
                        }


                        // this.setData({ name: "invoiceDataGet", data: res.data });
                        // handleNavigate(`/user-invoice/${res?.data?.fileName}`)
                        // window.open(`http://localhost:3000/user-invoice/${res?.data?.fileName}`, '_blank');
                    }
                })
                .catch(err => {
                    customNotification(err.message, "error")
                })
        },
    })
}

export default invoicemodel