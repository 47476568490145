import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const useplayResponsibility = {
  state: [],
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getuseplayResponsibilityhow(payload) {
      await axiosClient
        .post("/users/play_responsibly_module", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "playResponsibility", data: res.data });
          } else if (payload.is_download === 1) {
            window.open(res.data.path, "_self");
          } else {
            // customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default useplayResponsibility;
