import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import CSVDownload from "react-csv/src/components/Download";
const UpiListModel = {
  state: [],
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    // setData(state, payload) {
    //     return [...payload];
    // }
  },
  effects: (dispatch) => ({
    async getUserUpiList(payload) {
      await axiosClient
        .post("/document/upidetail", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.getUserUpiList({ ...payload, page: `${+payload.page - 1}` });
              return;
            }
            this.setData({ name: "UserUpiList", data: res.data });
          } else if (payload.is_download == 1) {
            // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
            // this.setData({
            //   name:
            //     payload.status === "PENDING"
            //       ? "CsvComp"
            //       : payload.status === "SUCCESS"
            //       ? "CsvComps"
            //       : payload.status === "REJECTED"
            //       ? "CsvCompr"
            //       : "",
            //   data: <CSVDownload data={res.data} target="_blank" />,
            // });
            window.open(res.data.path, "_self");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getBlockUpiUser(payload) {
      const { AcceptUser, pageInfo } = payload;
      await axiosClient
        .post(`/document/upi_action `, AcceptUser)
        .then((res) => {
          if (res.data.status === 200) {
            this.getUserUpiList(pageInfo);
            customNotification(res.data.message, "info");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default UpiListModel;
