import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const userTDS = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data, ...payload };
        },
    },
    effects: (dispatch) => ({

        async getTDSUser(payload) {
            await axiosClient.post('/users/tds_module', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getTDSUser({ ...payload, page: `${+payload.page - 1}` });
                        return;
                    }
                    this.setData({ name: "tdsUserData", data: res.data });
                } else if (payload.is_download == 1) {
                    window.open(res.data.path, '_self')
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTDSShow(payload) {
            await axiosClient.post('/users/entry_wise', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getTDSShow({ ...payload, page: `${+payload.page - 1}` });
                        return;
                      }
                    this.setData({ name: "tdsData", data: res.data });
                } else if (payload.is_download == 1) {
                    window.open(res.data.path, '_self')
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTDSViewShow(payload) {
            await axiosClient.post('/users/tds_module1', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "tdsviewData", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
    }),
};

export default userTDS;