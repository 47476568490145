import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
// import CSVDownload from "react-csv/src/components/Download";

const documentListModal = {
  state: {
    pageData: {
      page: "0",
      perpage: "100",
      docType: "PAN",
      status: "SUCCESS",
      is_download: "0",
      isNotificationRead: false,
      search:"",
    },
  },
 
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },
  },
  effects: (dispatch) => ({
    async getUserPanList(_,state) {
      const payload =  _ ?? state.documentListModal.pageData;
      await axiosClient
        .post("document/pandetail", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.getUserPanList();
              return;
            }
            this.setData({ name: "userPanList", data: res.data });
          } else if (payload.is_download == "1") {
            window.open(res.data.path, "_self");
            // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getUserPanListCounter(payload) {
      await axiosClient
        .post("/document/counter", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "userPanListCounter", data: res.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async deleteDocument(payload) {
      const { body, pageInfo } = payload;
      await axiosClient
        .post("/document/delete_action", body)
        .then((res) => {
          if (res.data.status === 200) {
            // this.setData({ name: "userPanListCounter", data: res.data });
            if (body?.docType === "BANK") {
              dispatch.bankpanListModel.getUserBankList({ ...pageInfo });
            } else if (body?.docType === "UPI") {
              dispatch.UpiListModel.getUserUpiList({ ...pageInfo });
            } else {
              this.getUserPanList({ ...pageInfo });
            }
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default documentListModal;
