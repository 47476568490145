import React from "react";

const Login = React.lazy(() => import('./../pages/authantication/Login'));
const ForgotPassword = React.lazy(() => import('./../pages/authantication/ForgotPassword'));
const Dashboard = React.lazy(() => import('./../pages/Dashboard'));
const PersonalDetails = React.lazy(() => import('./../pages/UserProfile/PersonalDetails'))
const Balance = React.lazy(() => import("./../pages/UserProfile/Balance"))
const AddCashStatement = React.lazy(() => import("./../pages/UserProfile/AddCashStatement"))
const RequestWithdrawal = React.lazy(() => import("./../pages/UserProfile/RequestWithdrawal"))
const AccountStatement = React.lazy(() => import("./../pages/UserProfile/AccountStatement"))
const KYC = React.lazy(() => import("./../pages/UserProfile/KYC"))
const TDS = React.lazy(() => import("./../pages/UserProfile/TDS"))
const GamesSummary = React.lazy(() => import("./../pages/UserProfile/GamesSummary"))
const PlayResponsibility = React.lazy(() => import("./../pages/UserProfile/PlayResponsibility"))
const RatingFeedback = React.lazy(() => import("./../pages/UserProfile/RatingFeedback"))
const SecurityLogout = React.lazy(() => import("./../pages/UserProfile/SecurityLogout"))
const ContactUs = React.lazy(() => import("./../pages/UserProfile/ContactUs"))
const EmailText = React.lazy(() => import("./../pages/UserProfile/EmailText"))
const Users = React.lazy(() => import('./../pages/Users'));
const BlockUsers = React.lazy(() => import('./../pages/BlockUsers'));
const Bot = React.lazy(() => import('./../pages/Bot'));
const PoolCategory = React.lazy(() => import('./../pages/Category/PoolCategory'));
const PointsCategory = React.lazy(() => import('./../pages/Category/PointsCategory'));
const Feedback = React.lazy(() => import("../pages/Feedback"));
const ReportProblem = React.lazy(() => import("../pages/ReportProblem"));
const ChipsReport = React.lazy(() => import("../pages/Report/ChipsReport"));
const DailyReport = React.lazy(() => import("../pages/Report/DailyReport"));
const MonthlyReport = React.lazy(() => import("../pages/Report/MonthlyReport"));
const PlayTableReport = React.lazy(() => import("../pages/Report/PlayTableReport"));
const NewUsers = React.lazy(() => import("../pages/Report/NewUsers"));
const ErrorCode = React.lazy(() => import("../pages/PreConfig/ErrorCode"));
const ChatManager = React.lazy(() => import("../pages/PreConfig/ChatManager"));
const ChipsHistory = React.lazy(() => import("../pages/Statistics/ChipsHistory"));
const CashHistory = React.lazy(() => import("../pages/Statistics/CashHistory"));
const LiveTable = React.lazy(() => import("../pages/Statistics/LiveTable"));
const TableTrack = React.lazy(() => import("../pages/Statistics/TableTrack"));
const NotificationTrack = React.lazy(() => import("../pages/Statistics/NotificationTrack"));
const ViewAll = React.lazy(() => import("../pages/Leaderboard/ViewAll"));
const UserData = React.lazy(() => import("../pages/Leaderboard/UserData"));
const WinnerPlayers = React.lazy(() => import("../pages/Leaderboard/WinnerPlayer"));
const ConfigLB = React.lazy(() => import("../pages/Leaderboard/Config")) ;
const LevelOption = React.lazy(() => import("../pages/LevelOption"));
const InAppIdList = React.lazy(() => import("../pages/CommonPages/InAppIdList"));
const PromotionOffer = React.lazy(() => import("../pages/CommonPages/PromotionOffer"));
const FeatureRollOut = React.lazy(() => import("../pages/FeatureRollOut"));
const DailyReward = React.lazy(() => import("../pages/DailyReward"));
const DealCategory = React.lazy(() => import("../pages/Category/DealCategory"));
const Config = React.lazy(() => import("../pages/PreConfig/Config"));
const History = React.lazy(() => import("./../pages/History"))
const Banner = React.lazy(() => import("./../pages/Banner"))
const UserPanList = React.lazy(() => import("./../pages/DocumentsList/UserPanList"))
const UserSuccessList = React.lazy(() => import("./../pages/DocumentsList/UserSuccessList"))
const UserRejectList = React.lazy(() => import("./../pages/DocumentsList/UserRejectList"))
const BankPanList = React.lazy(() => import("./../pages/DocumentsList/BankPanList"))
const SuccessList = React.lazy(() => import("./../pages/DocumentsList/SuccessList"))
const RejectList = React.lazy(() => import("./../pages/DocumentsList/RejectedPanList"))
const UpipendingList = React.lazy(() => import("./../pages/DocumentsList/UpiPendingList"))
const UpiSuccessList = React.lazy(() => import("./../pages/DocumentsList/UpiSuccessList"))
const UpiRejectedList = React.lazy(() => import("./../pages/DocumentsList/UpiRejectedList"))
const MailVerification = React.lazy(() => import("./../pages/DocumentsList/MailVerification"))
const DocumentHistory = React.lazy(() => import("./../pages/DocumentsList/DocumentHistory"))
const WithdrawRequests = React.lazy(() => import("./../pages/WithdrawRequests"))
const SuccessRequests = React.lazy(() => import("./../pages/WithdrawRequests/SuccessRequests"))
const PendingRequests = React.lazy(() => import("./../pages/WithdrawRequests/PendingRequests"))
const ProcessingRequest = React.lazy(() => import("./../pages/WithdrawRequests/ProcessingRequest"))
const FailedRequest = React.lazy(() => import("./../pages/WithdrawRequests/FailedRequest"))
const RejectedRequests = React.lazy(() => import("./../pages/WithdrawRequests/RejectedRequest"))
const Bonus = React.lazy(() => import("./../pages/Bonus"))
const AccountStatements = React.lazy(() => import("./../pages/UserProfileSection/AccountStatments"))
const AddCashStatements = React.lazy(() => import("./../pages/UserProfileSection/AddCashStatments"))
const TdsData = React.lazy(() => import("./../pages/UserProfileSection/TDS"))
const Invoice = React.lazy(() => import("./../pages/UserProfileSection/Invoice"))
const querypen = React.lazy(() => import("../pages/UserProfileSection/querypen"))
const querysuccess = React.lazy(() => import("../pages/UserProfileSection/querysuccess"))
const queryreje = React.lazy(() => import("../pages/UserProfileSection/queryreje"))
const BalanceData = React.lazy(() => import("./../pages/UserProfileSection/Balance"))
const userBonus = React.lazy(() => import("./../pages/UserProfileSection/UserBonus"))
const PlayResponsibilitys = React.lazy(() => import("./../pages/UserProfileSection/PlayResponsibilitys"))
const RatingFeedbacks = React.lazy(() => import("./../pages/UserProfileSection/Rating&Feedback"))
const UserPersonalDetails = React.lazy(() => import("./../pages/UserProfileSection/PersonalDetails"))
const GameSummery = React.lazy(() => import("./../pages/UserProfileSection/GameSummery"))
const Location = React.lazy(() => import("./../pages/Location"))
const DashboardUsers = React.lazy(() => import("./../pages/DashboardUsers"))
const SubAdmin = React.lazy(() => import("./../pages/SubAdmin"))
const playPending = React.lazy(() => import("./../pages/PlayStore/PlayPending"))
const playSuccess = React.lazy(() => import("./../pages/PlayStore/PlaySuccess"))
const playReject = React.lazy(() => import("./../pages/PlayStore/PlayReject"))
const invoice = React.lazy(() => import("../pages/UserProfileSection/Invoice/Displayinvoice"))
const IPAddress = React.lazy(() => import('./../pages/authantication/IpAddress'));
const RaiseIssue = React.lazy(() => import('../pages/UserProfileSection/RaiseIssue'))


export const PublicRoutesArr = [
    { path: "/", exact: true, component: Login },
    { path: "/forget", exact: true, component: ForgotPassword },
];

export const PrivateRoutesArr = [
    { path: "/user-invoice/:id", exact: true, name: "Display Invoice", component: invoice },
    { path: "/dashboard", exact: true, name: "Dashboard", component: Dashboard, icontype: "tim-icons icon-chart-pie-36" },
    { path: "/user-personal-details", exact: true, name: "Personal Details", component: PersonalDetails },
    { path: "/balance", exact: true, name: "Balance", component: Balance },
    { path: "/add-cash-statement", exact: true, name: "Add Cash Statement", component: AddCashStatement },
    { path: "/req-withdrawal", exact: true, name: "Request Withdrawal", component: RequestWithdrawal },
    { path: "/account-statement", exact: true, name: "Account Statement", component: AccountStatement },
    { path: "/kyc", exact: true, name: "KYC", component: KYC },
    { path: "/tds", exact: true, name: "TDS", component: TDS },
    { path: "/games-summary", exact: true, name: "Games Summary", component: GamesSummary },
    { path: "/play-responsibily", exact: true, name: "Play Responsibility", component: PlayResponsibility },
    { path: "/rating-feedback", exact: true, name: "Rating Feedback", component: RatingFeedback },
    { path: "/security-logout", exact: true, name: "Security Logout", component: SecurityLogout },
    { path: "/contact-us", exact: true, name: "Contact Us", component: ContactUs },
    { path: "/email-text", exact: true, name: "Email Text", component: EmailText },
    { path: "/users", exact: true, name: "Users", component: Users, icontype: "fa fa-user" },
    { path: "/block-users", exact: true, name: "Block Users", component: BlockUsers },
    { path: "/bot", exact: true, name: "Bot", component: Bot, icontype: "tim-icons icon-chat-33" },
    { path: "/feedback", exact: true, name: "Feedback", component: Feedback, icontype: "tim-icons icon-chat-33" },
    { path: "/pool-category", exact: true, name: "Pool Category", component: PoolCategory },
    { path: "/classic", exact: true, name: "Points Category", component: PointsCategory },
    { path: "/Table-feedback", exact: true, name: "Report A Problem", component: ReportProblem },
    { path: "/chips-report", exact: true, name: "Admin Given Chips Report", component: ChipsReport },
    { path: "/daily-report", exact: true, name: "Daily Report", component: DailyReport },
    { path: "/monthly-report", exact: true, name: "Monthly Report", component: MonthlyReport },
    { path: "/play-table-report", exact: true, name: "Play table report", component: PlayTableReport },
    { path: "/new-users", exact: true, name: "New users", component: NewUsers },
    { path: "/error-code", exact: true, name: "Error Code", component: ErrorCode },
    { path: "/chat-manager", exact: true, name: "Chat Manager", component: ChatManager },
    { path: "/chips-history", exact: true, name: "CHIP History", component: ChipsHistory },
    { path: "/cash-history", exact: true, name: "Cash History", component: CashHistory },
    { path: "/live-table", exact: true, name: "Live Table", component: LiveTable },
    { path: "/table-track", exact: true, name: "Table Track", component: TableTrack },
    { path: "/notification-track", exact: true, name: "Notifications", component: NotificationTrack },
    { path: "/view-all", exact: true, name: "View All", component: ViewAll },
    { path: "/view-all/:leaderboardId", exact: true, name: "User Detail", component: UserData },
    { path: "/winner-player-list/:leaderboardId", exact: true, name: "Winner Players", component: WinnerPlayers },
    { path: "/config-lb", exact: true, name: "Config lb", component: ConfigLB },
    { path: "/level-points", exact: true, name: "Level Option", component: LevelOption },
    { path: "/android-offer", exact: true, name: "In App ID list", component: InAppIdList },
    { path: "/android-promotion", exact: true, name: "Promotion Offer", component: PromotionOffer },
    { path: "/ios-offer", exact: true, name: "In App ID list", component: InAppIdList },
    { path: "/ios-promotion", exact: true, name: "Promotion Offer", component: PromotionOffer },
    { path: "/html-offer", exact: true, name: "In App ID list", component: InAppIdList },
    { path: "/html-promotion", exact: true, name: "Promotion Offer", component: PromotionOffer },
    { path: "/feature-roll-out", exact: true, name: "Feature Roll Out", component: FeatureRollOut },
    { path: "/daily-reward", exact: true, name: "Report A Problem", component: DailyReward },
    { path: "/deal-category", exact: true, name: "Deal Category", component: DealCategory },
    { path: "/config", exact: true, name: "Config", component: Config },
    { path: "/history", exact: true, name: "History", component: History },
    { path: "/banner", exact: true, name: "Banner", component: Banner },
    { path: "/user-pan-list", exact: true, name: "User Pan List", component: UserPanList },
    { path: "/user-success-list", exact: true, name: "User Pan List", component: UserSuccessList },
    { path: "/user-reject-list", exact: true, name: "User Pan List", component: UserRejectList },
    { path: "/bank-pan-list", exact: true, name: "Pending List", component: BankPanList },
    { path: "/bank-success-list", exact: true, name: "Success List", component: SuccessList },
    { path: "/bank-reject-list", exact: true, name: "Rejected List", component: RejectList },
    { path: "/bank-Upipending-list", exact: true, name: "Pending List", component: UpipendingList },
    { path: "/bank-Upisuccess-list", exact: true, name: "Success List", component: UpiSuccessList },
    { path: "/bank-Upireject-list", exact: true, name: "Rejected List", component: UpiRejectedList },
    { path: "/mail-verification", exact: true, name: "Mail Verification", component: MailVerification },
    { path: "/document-history", exact: true, name: "Document History", component: DocumentHistory },
    { path: "/withdraw-Requests", exact: true, name: "Withdraw Request", component: WithdrawRequests },
    { path: "/succes-Requests", exact: true, name: "Success Requests", component: SuccessRequests },
    { path: "/pending-Requests", exact: true, name: "Pending Requests", component: PendingRequests },
    { path: "/bank-process-list", exact: true, name: "Processing List", component: ProcessingRequest },
    { path: "/reject-Requests", exact: true, name: "Rejected Requests", component: RejectedRequests },
    { path: "/failed-Requests", exact: true, name: "Rejected Requests", component: FailedRequest },
    { path: "/bonus", exact: true, name: "Bonus", component: Bonus },
    { path: "/account-statements", exact: true, name: "Account Statement", component: AccountStatements },
    { path: "/add-cash-statements", exact: true, name: "Add Cash Statement", component: AddCashStatements },
    { path: "/balances", exact: true, name: "Balance", component: BalanceData },
    { path: "/user-bonus", exact: true, name: "userBonus", component: userBonus },
    { path: "/play-responsibilys", exact: true, name: "Play Responsibility", component: PlayResponsibilitys },
    { path: "/game-summery", exact: true, name: "Game Summery", component: GameSummery },
    { path: "/rating-feedbacks", exact: true, name: "Rating Feedback", component: RatingFeedbacks },
    { path: "/user-personal-detail", exact: true, name: "Personal Details", component: UserPersonalDetails },
    { path: "/_tds", exact: true, name: "TDS", component: TdsData },
    { path: "/invoice", exact: true, name: "Invoice", component: Invoice },
    { path: "/query-pan-list", exact: true, name: "query-pan-list", component: querypen },
    { path: "/query-success-list", exact: true, name: "query-success-list", component: querysuccess },
    { path: "/query-reject-list", exact: true, name: "query-reject-list", component: queryreje },
    { path: "/location", exact: true, name: "Location", component: Location },
    { path: "/users-data", exact: true, name: "DashboardUsers", component: DashboardUsers },
    { path: "/sub-admin", exact: true, name: "Sub Admin", component: SubAdmin },
    { path: "/play-pending-Requests", exact: true, name: "Play Pending", component: playPending },
    { path: "/play-succes-Requests", exact: true, name: "Play Success", component: playSuccess },
    { path: "/play-reject-Requests", exact: true, name: "Play Reject", component: playReject },
    { path: "/ip-address", exact: true, name: "IP Address", component: IPAddress },
    { path: "/raise-issue", exact:true, name: "Raise Issue", component: RaiseIssue }

];