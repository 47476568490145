import React from "react";
import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const subAdminModel = {
  state: {
    registerNewUser: {},
    userControl: {},
    pageData: { page: "0",  perpage: "100" },
  },
  reducers: {
    setData(state, payload) {
        return { ...state, [payload.name]: payload.data };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },

  },
  effects: (dispatch) => ({
    async registerUser(payload) {
      await axiosClient
        .post("/register", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "registerNewUser", data: res.data });
            this.userControlCms();
            customNotification("User registered successfully", "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async userControlCms(_,state) {
      const payload = state.subAdminModel.pageData;
      await axiosClient
        .post("/user_controll_cms", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.userControlCms();
              return;
            }
            this.setData({ name: "userControl", data: res.data });
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async userDelete(payload) {
      await axiosClient
        .post("/user_controll_cms/delete", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "userRemove", data: res.data });
            this.userControlCms();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async userEdit(payload) {
      await axiosClient
        .post("/user_controll_cms/sub_roll_update", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "userEdit", data: res.data });
            this.userControlCms();
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async userBlock(payload) {
      await axiosClient
        .post("/user_controll_cms/blockAdmin", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.userControlCms();
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default subAdminModel;
