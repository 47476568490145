import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const LocationModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
          async getTabledata(payload) {
            await axiosClient.post('/users/location_all', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "locationtabel", data: res.data });
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },

        async getLocation(payload) {
            await axiosClient.post('/users/location', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "locationtabelget", data: res.data });
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
     
    }),
};

export default LocationModel;