import Modal from "antd/es/modal";

const CustomModal = ({ title,visible, onCancel, children, data, className }) => {
    return (
        <Modal title={title} className={`custom-modal ${className}`} data={data} footer={false} visible={visible} onCancel={onCancel} destroyOnClose={true}>
            {children}
        </Modal>
    )
};

export default CustomModal