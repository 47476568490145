import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const categoryModel = {
  state: {
    data: { poolCategory: [], pointsCategory: [], dealCategory: [] },
    pageData: {
      page: "0",
      perpage: "100",
      is_download: "0",
      IsBlock: false,
      mode: "cash",
      category: 61,
    },
  },

  reducers: {
    setData(state, payload) {
      return {
        ...state,
        data: { ...state.data, [payload.name]: payload.data },
      };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },
  },
  effects: (dispatch) => ({
    async getPoolCategory(_, state) {
      const payload = state.categoryModel.pageData;
      await axiosClient
        .post("/mode_category/pool_category_data", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.getPoolCategory();
              return;
            }
            this.setData({ name: "poolCategory", data: res.data });
          } else if (payload.is_download == 1) {
            window.open(res.data.path, "_self");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() =>{
            this.setPageData({...payload,is_download:'0'})
        })
    },
    async getDealCategory(_, state) {
      const payload = state.categoryModel.pageData;
      delete payload.category;
      await axiosClient
        .post("/mode_category/deal_category_data", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload,page: `${+payload.page - 1}`});
              this.getDealCategory();
              return;
            }
            this.setData({ name: "dealCategory", data: res.data });
          } else if (payload.is_download == 1) {
            window.open(res.data.path, "_self");
          }
          else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() =>{
          this.setPageData({...payload,is_download:'0'})
      })
    },
    async getPointsCategory(_, state) {
      const payload = state.categoryModel.pageData;
      delete payload.category;
      await axiosClient
        .post("/mode_category/classic_category_data", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.getPointsCategory();
              return;
            }
            this.setData({ name: "pointsCategory", data: res.data });
          } else if (payload.is_download == 1) {
            window.open(res.data.path, "_self");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() =>{
          this.setPageData({...payload,is_download:'0'})
      })
    },
    async addPoolCategory(payload) {
      await axiosClient
        .post("/mode_category/in_pool_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPoolCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async addPointsCategory(payload) {
      await axiosClient
        .post("/mode_category/in_classic_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPointsCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async addDealCategory(payload) {
      await axiosClient
        .post("/mode_category/in_deal_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getDealCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async updatePoolCategory(payload) {
      await axiosClient
        .post("/mode_category/up_pool_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPoolCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async updatePointsCategory(payload) {
      await axiosClient
        .post("/mode_category/up_classic_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPointsCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async updateDealCategory(payload) {
      await axiosClient
        .post("/mode_category/up_deal_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getDealCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async deleteCategoryPool(payload) {
      await axiosClient
        .post("/mode_category/del_pool_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPoolCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async deleteCategoryPoints(payload) {
      await axiosClient
        .post("/mode_category/del_classic_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getPointsCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async deleteCategoryDeal(payload) {
      await axiosClient
        .post("/mode_category/del_deal_category", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getDealCategory();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
  }),
};

export default categoryModel;
