import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
// import CSVDownload from "react-csv/src/components/Download";
const bankpanListModel = {
  state: [],
  // dropDown: [],
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    // setData(state, payload) {
    //     return [...payload];
    // }
  },
  effects: (dispatch) => ({
    // async getUserPanList(payload) {
    //     await axiosClient.post('/document/bankdetail', payload).then(res => {
    //         if (res.data.status === 200) {
    //             this.setData(res.data);
    //         } else {
    //             customNotification(res.data.message, "info")
    //         }
    //     }).catch(err => {
    //         customNotification(err.message, "error")
    //     })
    // },
    async getUserBankList(payload) {
      await axiosClient
        .post("/document/bankdetail", payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.getUserBankList({...payload, page: `${+payload.page - 1}`});
              return;
            }
            this.setData({ name: "bankPanList", data: res.data });
          } else if (payload.is_download === "1") {
            window.open(res.data.path, "_self");
            // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
            // this.setData({ name: payload.status ==="PENDING"? "CsvComp"
            // :payload.status ==="SUCCESS"?"CsvComps"
            // :payload.status ==="REJECTED"?"CsvCompr"
            // :'',
            // data: <CSVDownload data={res.data} target="_blank" /> });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getBlockUser(payload) {
      const { AcceptUser, pageInfo, check, formData } = payload;
      await axiosClient
        .post(`/document/bank_action `, AcceptUser)
        .then((res) => {
          if (res.data.status === 200) {
            this.getUserBankList(pageInfo);
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
    async getUserBankListCounter(payload) {
      await axiosClient
        .post("/document/counter", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({
              name:
                payload.status === "PENDING"
                  ? "userBankListCounter1"
                  : payload.status === "SUCCESS"
                  ? "userBankListCounter"
                  : payload.status === "REJECTED"
                  ? "userBankListCounter2"
                  : "",
              data: res.data,
            });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async getUserRejectList(payload) {
      const { AcceptUser, pageInfo } = payload;
      await axiosClient
        .post("/document/bank_action", AcceptUser)
        .then((res) => {
          if (res.data.status === 200) {
            this.getUserBankList(pageInfo);
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
    async bankDropDownList() {
      await axiosClient
        .post("/dashboard/get_reason")
        .then((res) => {
          if (res.data.status === 200) {
            this.setData({ name: "dropDown", data: res.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
  }),
};

export default bankpanListModel;
