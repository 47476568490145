import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const userAddcaseStatment = {
    state: [],
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({
        // async addBalanceData(payload) {
        //     await axiosClient.post('/content/up_balance', payload).then(res => {
        //         if (res.data.status === 200) {
        //             // getBalanceData(payload)
        //             this.getBalanceDataShow(payload)
        //             customNotification(res.data.message, "success")
        //         } else {
        //             customNotification(res.data.message, "info")
        //         }
        //     }).catch(err => {
        //         customNotification(err.message, "error")
        //     })
        // },
        async getAddcaseStatmentShow(payload) {
            await axiosClient.post('/users/add_cash_statement_module', payload).then(res => {
                if (res.data.status === 200) {
                    if (res.data.data.length === 0 && res.data.count > 0) {
                        this.getAddcaseStatmentShow({ ...payload, page: `${+payload.page - 1}` });
                        return;
                    }
                    let data = res.data?.data?.reduce((acc, cur, i) => {
                        return [...acc, { ...cur, index: i + 1 }]
                    }, [])
                    this.setData({ name: "addcase", data: { ...res.data, data } });
                } else if (payload.is_download === 1) {
                    window.open(res.data.path, '_self')
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },

        async getviewcaseStatmentShow(payload) {
            await axiosClient.post('/users/add_cash_statement_module1', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "addcaseview", data: res.data });
                } else if (payload.is_download === 1) {
                    window.open(res.data.path, '_self')
                } else {
                    // customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async searchData(payload) {
            const { search_type, q1, data, q2 } = payload;
            let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
            await axiosClient.post(`/search?${link}`, data).then(res => {
                if (res.data.status === 200) {
                    let data = res.data?.data?.reduce((acc, cur, i) => {
                        return [...acc, { ...cur, index: i + 1 }]
                    }, [])
                    this.setData({ name: "addcase", data: { ...res.data, data } });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
    }),
};

export default userAddcaseStatment;