import axiosClient from "../../api/axiosClient";
import Cookies from 'universal-cookie';
import { expireTime } from "../../utils";
import customNotification from "../../components/notification";

const cookies = new Cookies();

const userModel = {
    state: {
        userDetails: JSON.parse(localStorage.getItem('userDetails') || '{}'),
        otpResponce: {},
        verifyOtpResponce: {},
        sideBarMenuAccessArr:null,
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
        setUserDetails(state, payload) {
            return { ...state, ...payload };
        },
       
    },
    effects: (dispatch) => ({
        async loginAdmin(payload) {
            const { record, handleNavigate } = payload;
            await axiosClient.post('/login', record).then(res => {
                if (res.data.status === 200) {
                    const remember = localStorage.getItem("remember") === "false" ? false : true
                    {
                        remember == true && cookies.set(`access_token`, res.data.data, { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                        remember == true && cookies.set(`u_id`, res.data.user_id, { path: '/', expires: expireTime(), domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    }
                    {
                        remember == false && sessionStorage.setItem("access_token", res.data.data)
                        remember == false && sessionStorage.setItem("u_id", res.data.user_id)
                    }
                    this.setUserDetails(res.data);
                    localStorage.setItem('userDetails', JSON.stringify(res.data));
                    handleNavigate('/dashboard');
                } else {
                    customNotification(res.data?.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async changePassword(payload) {
            const { formData, handleNavigate } = payload;
            await axiosClient.post('/changepassword', formData).then(res => {
                if (res.data.status === 200) {
                    customNotification(res.data.message, "success")
                    handleNavigate('/');
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async forgotPassword(payload) {
            const { formData, handleNavigate } = payload;
            await axiosClient.post('/forgetpassword', formData).then(res => {
                if (res.data.status === 200) {
                    customNotification(res.data.message, "success")
                    handleNavigate('/');
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async sendOtp(payload) {
            await axiosClient.post('/login/getOtp', payload).then(res => {
                this.setData({ name: "otpResponce", data: res.data });
                if (res.data.status !== 200) {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getPermission(payload) {
            await axiosClient.post('/login/getPermission',payload).then(res => {
                if(res.data.status === 200){
                    this.setUserDetails({sideBarMenuAccessArr:res.data.ress.data});
                    // customNotification(res.data.message,"success")
                } else {
                    customNotification(res.data.message,"info")
                }
            }).catch(err => {
                customNotification(err.message,"error")
            })
        },
        async verifyOtp(payload) {
            await axiosClient.post('/users/verify_otp', payload).then(res => {
                this.setData({ name: "verifyOtpResponce", data: res.data });
                if (res.data.status !== 200) {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async resetVerifyOtpResponse() {
            await this.setData({ name: "verifyOtpResponce", data: {} });
        },
        async otpResponse() {
            await this.setData({ name: "otpResponce", data: {} });
        },
    }),
};

export default userModel;