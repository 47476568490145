import React, {useState} from "react";
import {Form} from "antd";
import Input from "antd/es/input";
import store from "../../rematch/store";
import EyeTwoTone from "@ant-design/icons/lib/icons/EyeTwoTone";
import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";

const ChangePassword = ({ handleNavigate }) => {
    const [formData, setFormData] = useState({});
    const { dispatch } = store;
    const [form] = Form.useForm();

    const handleSubmit = () => {
        let temp = {...formData}
        delete temp.confirmPassword;
        dispatch.userModel.changePassword({ formData: temp, handleNavigate });
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]:e.target.value });
    }

    const rules = {
        email: [
            { required: true, message: "Email is required" },
            {
              pattern: new RegExp(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]{2,}$/),
              message: "Please enter a valid email address.",
            },
          ],
        oldpassword: [
            {
                required: true,
                message: "Please enter your current password"
            },
        ],
        newpassword: [
            ({ getFieldValue }) => ({
                validator(_, value) {
                    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{9,}$/;
                    if (!value) {
                        return Promise.reject("Please enter your new password");
                    } else if (!re.test(value)) {
                        return Promise.reject("Not a valid password");
                    } else if (!value || getFieldValue('oldpassword') !== value) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject(new Error('New password cannot be same as current password!'));
                    }
                },
            }),
        ],
        confirmPassword: [
            {
                required: true,
                message: "Please enter your confirm password"
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
            }),
        ],
    };

    return (
        <div className="pwd-container">
            <div className="pwd-container_content">
                <h2 className="pwd-container_heading">Change Password</h2>
                <div className="pwd-container_card">
                    <Form
                        form={form}
                        name="pwd-form"
                        onFinish={handleSubmit}
                        scrollToFirstError
                        initialValues={formData}
                        className={"pwd-form"}
                    >
                        <Form.Item label="Email Address *" name="email" rules={rules.email}>
                            <Input name="email" onChange={handleChange} type="text" value={formData.email} />
                        </Form.Item>
                        <Form.Item label="Old Password *" name="oldpassword" rules={rules.oldpassword}>
                            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} autoComplete={"off"} name="oldpassword" onChange={handleChange} type="password" value={formData.oldpassword} />
                        </Form.Item>
                        <Form.Item dependencies={['oldpassword']} label="New Password *" name="newpassword" rules={rules.newpassword}>
                            <div>
                                <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} name="newpassword" onChange={handleChange} type="password" value={formData.newpassword} />
                                <p className="mb-0">Password must be minimum nine characters, at least one letter, one number and one special character.</p>
                            </div>
                        </Form.Item>
                        <Form.Item dependencies={['newpassword']} label="Confirm Password *" name="confirmPassword" rules={rules.confirmPassword}>
                            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} name="confirmPassword" onChange={handleChange} type="password" value={formData.confirmPassword} />
                        </Form.Item>
                        <Form.Item>
                            <button type="submit" className="btn btn-success mr_24">Submit</button>
                            <button onClick={() => handleNavigate('/')} type="button" className="btn btn-warning">Cancel</button>
                        </Form.Item>
                    </Form>
                </div >
            </div>
        </div >
    )
};

export default ChangePassword;