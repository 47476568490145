import axiosClient from "../../api/axiosClient";
import React from "react";
import CSVDownload from "react-csv/src/components/Download";
import customNotification from "../../components/notification";

const reportModel = {
    state: {
        chipsReport: {
            data: []
        },
        dailyReport: [],
        monthlyReport: [],
        playReport: {
            data: [],
            count: 0
        },
        newUsers: {
            data: [],
            count: 0
        },
        CsvComp: null
    },
    reducers: {
        setReportData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({
        async getChipsReportData(payload) {
            await axiosClient.post('/admin_given_chips_report', payload).then(res => {
                if (res.data.status === 200) {
                    this.setReportData({ name: "chipsReport", data: res.data.data });
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setReportData({ name: "chipsReport", data: [] });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportData({ name: "CsvComp", data: null });
                }
            })
        },
        async getMonthlyReportData(payload) {
            await axiosClient.post('/monthly_active_user_report', payload).then(res => {
                if (res.data.status === 200) {
                    this.setReportData({ name: "monthlyReport", data: res.data.data });
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setReportData({ name: "monthlyReport", data: [] });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportData({ name: "CsvComp", data: null });
                }
            })
        },
        async getDailyReportData(payload) {
            await axiosClient.post('/daily_active_user_report', payload).then(res => {
                if (res.data.status === 200) {
                    this.setReportData({ name: "dailyReport", data: res.data.data });
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setReportData({ name: "dailyReport", data: [] });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportData({ name: "CsvComp", data: null });
                }
            })
        },
        async getPlayReportData(payload) {
          return await axiosClient.post('/play_table_report', payload).then(res => {
                if (res.data.status === 200) {
                    let response = res.data.data;
                    let newResponse = [];
                    response.map((data, index) => {
                        newResponse.push({
                            key: index,
                            date: data.date,
                            ...data.tablelist[0],
                            children: [
                                ...data.tablelist.slice(1, data?.tablelist?.length - 1),
                                {...data.tablelist[data.tablelist.length - 1], total: data.tablelist.reduce((acc, cur) => acc + cur.counter, 0)}
                            ]
                        });
                    });
                    this.setReportData({ name: "playReport", data: {
                            data: newResponse,
                            count: res.data.count
                        } });
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setReportData({ name: "playReport", data: { data: [], count: 0 } });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
              customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportData({ name: "CsvComp", data: null });
                }
          })
        },
        async getNewUsersData(payload) {
            const { mode, roundType, ...newPayload } = payload;
            await axiosClient.post(`/game_play_management/${mode}`, newPayload).then(res => {
                if (res.data.status === 200) {

                    let response = res.data.data;
                    let newResponse = [];
                    response.map((data, index) => {
                        newResponse.push({
                            key: index,
                            newData: [
                                ...data
                            ]
                        });
                    });
                    this.setReportData({ name: "newUsers", data: {
                        data: newResponse,
                            count: res.data.count
                        }});
                } else if (payload.is_download === 1 && !res.data.status) {
                    this.setReportData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                } else {
                    this.setReportData({ name: "newUsers", data: { data: [], count: 0 } });
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                if (payload.is_download === 1) {
                    this.setReportData({ name: "CsvComp", data: null });
                }
            })
        },
    }),
};

export default reportModel;