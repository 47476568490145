import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const ipAddressModel = {
  state: {
    ipData: [],
  },
  reducers: {
    setIpData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },
  },
  effects: (dispatch) => ({
    async getIPData() {
      await axiosClient
        .get("/config/getIp",{})
        .then((res) => {
          if (res.data.status === 200) {
            this.setIpData({ name: "ipData", data: res.data.data });
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        });
    },
   
    async updateIpData(payload) {
      await axiosClient
        .post("/config/ipChange", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.getIPData();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
        
  }),
};

export default ipAddressModel;
