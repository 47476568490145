import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";

const bonusModel = {
    state: [],
    reducers: {
        setData(state, payload) {
            return {...state, [payload.name]: payload.data,...payload };
        },
    },
    effects: (dispatch) => ({
        async addBonusData(payload) {
            await axiosClient.post('/bonus/up_ref_bonus', payload).then(res => {
                if (res.data.status === 200) {
                    // this.setData(payload );
                    this.getBonusDataShow(payload)
                   
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getBonusDataShow(payload) {
            await axiosClient.post('/bonus/ref_bonus', payload).then(res => {
                if (res.data.status === 200) {
                   this.setData({ name: "bonus", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        }
    }),
};

export default bonusModel;