import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";

const levelOptionModel = {
    state: [],
    reducers: {
        setLevelData(state, payload) {
            return [...payload];
        }
    },
    effects: (dispatch) => ({
        async getLevelOption() {
            await axiosClient.post('/level_manager').then(res => {
                if (res.data.status === 200) {
                    this.setLevelData(res.data.data);
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async updateLevelOption(payload) {
            await axiosClient.post('/level_manager/update', payload).then(res => {
                if (res.data.status === 200) {
                    this.getLevelOption();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            });
        },
        async deleteLevelOption(payload) {
            await axiosClient.post('/level_manager/delete', payload).then(res => {
                if (res.data.status === 200) {
                    this.getLevelOption();
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            }).finally(() => {
                dispatch.modalModel.setModalData()
            })
        },
    }),
};

export default levelOptionModel;