import axiosClientGame from "../../api/axiosClientGame";
import customNotification from "../../components/notification";

const raiseIssueModel = {

  state: {
    raiseIssueData: [],
   
    pageData: {
      page: "0",
      perpage: "100",
      is_download: "0",
      IsBlock: false,
      search: "",
    },
  },
  reducers: {
    setData(state, payload) {
      return { ...state, [payload.name]: payload.data };
    },
    setPageData(state, payload) {
      return { ...state, pageData: payload };
    },
  },

  effects: (dispatch) => ({
      async getRaiseIssueView(_, state) {
      const payload = state.raiseIssueModel.pageData;
      await axiosClientGame
        .post(`/raiseIssueList`, payload)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length === 0 && res.data.count > 0) {
              this.setPageData({ ...payload, page: `${+payload.page - 1}` });
              this.getRaiseIssueView();
              return;
            }
            this.setData({ name: "raiseIssueData", data: res.data });
          } else if (payload.is_download == 1) {
            window.open(res.data.path, "_self");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          this.setPageData({...payload,is_download:'0'})
        });
    },

    async deleteRaiseIssueData(payload) {
      await axiosClientGame
        .delete("/raiseIssue", { data: payload })
        .then((res) => {
          if (res.data.status === 200) {
            this.getRaiseIssueView();
            customNotification(res.data.message, "success");
          } else {
            customNotification(res.data.message, "info");
          }
        })
        .catch((err) => {
          customNotification(err.message, "error");
        })
        .finally(() => {
          dispatch.modalModel.setModalData();
        });
    },
  }),
};
export default raiseIssueModel;
