import axiosClient from "../../api/axiosClient";
import customNotification from "../../components/notification";
import moment from "moment";
import CSVDownload from "react-csv/src/components/Download";
import axios from "axios";

// import { saveAs } from 'file-saver';
const dashboardUser = {
    state: {
        userTable: {
            data: []
        },
        blokedUser: {}
    },
    reducers: {
        setData(state, payload) {
            return { ...state, [payload.name]: payload.data };
        },
    },
    effects: (dispatch) => ({

        async getdashBoardUser() {
            await axiosClient.post('/dashboard/users_data ').then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "dashboardUser", data: res.data });

                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getUserDataTable(payload) {
            //         var data = JSON.stringify({
            //             "IsBlock": false,
            //             "is_download": "1",
            //             "page": "0",
            //             "perpage": "20"
            //           });
            //           var config = {
            //             method: 'post',
            //             url: 'https://kube.artoon.in:32266/dashboard/user_data_table',
            //             headers: { 
            //                 'authorization': 'bearer 638f28aa46a57d57c1490ec7', 
            //               'responseType': 'blob',
            //               'Content-Type': 'application/json',
            //             },
            //             data : data
            //           };

            //           axios(config)
            //           .then(function (response) {
            //             // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // //   saveAs(response.data, 'fixi.xlsx');
            // const blob = new Blob([response.data], {
            //     type: "application/vnd.ms-excel;charset=utf-8"
            //   });
            //   saveAs(blob, "Report.xls");
            //           })
            //           .catch(function (error) {
            //           });
            //         axios.post('https://kube.artoon.in:32266/dashboard/user_data_table', {  responseType: 'arraybuffer', headers: { 
            //             'authorization': 'bearer 638f28aa46a57d57c1490ec7', 
            //           },})
            // .then((response) => {
            //   var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            //   saveAs(blob, 'fixi.xlsx');
            // }).catch(err=>{});
            await axiosClient.post('/dashboard/user_data_table', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "userTable", data: res.data });
                }
                else if (payload.is_download === "1") {
                    // this.setData({ name: "CsvComp", data: <CSVDownload data={res.data} target="_blank" /> });
                    // const url = window.URL.createObjectURL(new Blob([res.data.path]));
                    // const link = document.createElement('a');
                    // link.href = url;
                    // link.setAttribute('download', `${Date.now()}.xlsx`);
                    // document.body.appendChild(link);
                    // link.click();
                    window.open(res.data.path, '_self')
                }
                else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getBlockUser(payload, state) {
            const { blockUser, pageInfo, check, formData } = payload;
            await axiosClient.post(`/dashboard/block_user `, blockUser).then(res => {
                if (res.data.status === 400 || 200) {
                    this.getUserDataTable(pageInfo);
                    customNotification(res.data.message, "success")
                } else {
                    customNotification(res.data.message, "info");
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
            // .finally(() => {
            //     dispatch.modalModel.setModalData()
            // })
        },
        async getTotalCash(payload) {
            await axiosClient.post('/dashboard/user_deposit', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "viewdeposite", data: res.data });

                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalWithdraw(payload) {
            await axiosClient.post('/dashboard/user_withdraw', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "viewWithdraw", data: res.data });

                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async getTotalWinPlayer(payload) {
            await axiosClient.post('/dashboard/total_win_loss', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "viewWinPlayer", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        async userBlock(payload) {
            await axiosClient.post('/dashboard/user_block', payload).then(res => {
                if (res.data.status === 200) {
                    this.setData({ name: "blokedUser", data: res.data });
                } else {
                    customNotification(res.data.message, "info")
                }
            }).catch(err => {
                customNotification(err.message, "error")
            })
        },
        // async searchData(payload) {
        //     const { search_type, q1, data, q2 } = payload;
        //     let link = search_type === 'custom_date' ? `search_type=${search_type}&q1=${moment(q1).format("YYYY-MM-DD")}&q2=${moment(q2).format("YYYY-MM-DD")}` : `search_type=${search_type}&q1=${q1}`
        //     await axiosClient.post(`/search?${link}`, data).then(res => {
        //         if (res.data.status === 200) {
        //             this.setData({ name: "addAmount", data: res.data });
        //         } else {
        //             customNotification(res.data.message, "info")
        //         }
        //     }).catch(err => {
        //         customNotification(err.message, "error")
        //     });
        // },
    }),
};

export default dashboardUser;